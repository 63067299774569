import React from 'react';
import { Link } from 'gatsby';

import Grid from '@mui/material/Grid';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';

import graphicBook from '../images/book.svg';
import graphicDevice from '../images/device.svg';
import graphicDollar from '../images/dollar.svg';

const OurOrigins = () => {

  return (
    <Page title='Our Origins'>
      {/* Hero */}
      <Section wide>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Phone showing the Innowell App Dashboard' src={graphicBook}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Once upon a time...</Typography>
            <Typography type='paragraph' preset={2}>The ideas that underpin our mission had their beginnings in research done through the Young &amp; Well CRC; set up to explore technology's role in Youth and Adolescent Mental Health care. Running between 2011 and 2016 the research produced an early blueprint for the Synergy Online System – a digital tool that could support the mental health care and wellbeing of young people. </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section variant='wide'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='clinician graphic' src={graphicDollar}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='paragraph' preset={2}>Knowing that mental health care is an issue that affects people of all ages, from all walks of life, the Australian Commonwealth Government funded <Link to='/project-synergy'>Project Synergy</Link> with a $30 million grant to continue our research and broaden the understanding of how digital technologies could be used to improve mental health outcomes across the wider population. This work uniquely brought together academic researchers, clinical health professionals, digital technologists, and those with lived experiences to explore the challenges and practical solutions to deliver relevant and timely mental health care for those who need it – and may not realise they do.</Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicDevice}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='paragraph' preset={2}>To deliver this project, Professors Ian Hickie and Jane Burns led a cross disciplinary team to form Innowell - a digital, mental health platform validated through actual research by over 2,000 users across 13 locations and built by people with real, lived experiences from a variety of backgrounds, cultures, and skillsets.</Typography>
            <Typography type='paragraph' preset={2}>A tool built by real people to help real people. </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container>
          <Grid item xs={12}>
            <Typography type='paragraph' preset={2}>Fast forward, and today Innowell works with thousands of individuals across workplaces and clinical care at all levels to improve mental health, care for patients, and build a brighter, more mentally fit future for everyone. </Typography>
          </Grid>
        </Grid>
      </Section>
    </Page>
  )
};

export default OurOrigins;
